<template>
  <div class="has-text-centered hero">
    <div class="hero-body">
      <div class="container">
        <h1 class="title">
          <i class="fas fa-lock ml-4 fa-2x is-block mb-7 text-red-400"></i>
          <div class="border rounded-md border-red-100 ml-7 mr-7 p-6 text-red-300">
            Your account requires an authorization. <br>
            <span class="text-gray-400 is-block mt-2 is-size-5">
              Please, contact the administrator to activate your account.
            </span>
          </div>

        </h1>
      </div>
    </div>
  </div>
</template>
